import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAJl2cIm05yJlioCpGJVr4O6XYbg7rCOR4",
    authDomain: "klantenkaart-vergeten.firebaseapp.com",
    databaseURL: "https://klantenkaart-vergeten.firebaseio.com",
    projectId: "klantenkaart-vergeten",
    storageBucket: "klantenkaart-vergeten.appspot.com",
    messagingSenderId: "576115011775",
    appId: "1:576115011775:web:b04e262f39a9c34e5ad60a",
    measurementId: "G-SBKCR0CNC2"
  });

  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();

  export { db, auth, storage };
import React from 'react';
import './App.css';

var QRCode = require('qrcode.react');
var Barcode = require('react-barcode');

function Klantenkaart( {item, storeName, sortCode, format, value, bg, fg} ) {

    if (sortCode === 'Barcode') {
        return (
            <div style={{
                fontSize: '1.5vh',
                textAlign: 'center',
                marginBottom: '5px',
                background: bg,
                color: fg}}>

                <h2>{storeName.toUpperCase()}</h2>
                
                <Barcode id={item} className='barcode' value={value} background={bg} format={format} lineColor={fg} />
            </div>
        );

    } else {
        return (
            <div style={{
                fontSize: '1.5vh',
                textAlign: 'center',
                marginBottom: '5px',
                background: bg,
                color: fg}}>

                    <h2>{storeName}</h2>
                    <QRCode value={value} bgColor={bg} fgColor={fg}/>
                </div>
        );

    }
}

export default Klantenkaart;

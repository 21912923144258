import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.PNG';
import Klantenkaart from './Klantenkaart';
import { db } from './firebase';
import IconButton from '@material-ui/core/IconButton';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WJB7MP9'
}

function App() {

  const [card, setCards] = useState([]);

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    db.collection('Supermarkten').onSnapshot(snapshot => {
      setCards(snapshot.docs.map(doc => ({
        id: doc.id,
        card: doc.data()
      })));
    })
  }, []);

  const [cardBouwmarkt, setCardsBouwmarkt] = useState([]);

  useEffect(() => {
    db.collection('Bouwmarkt').onSnapshot(snapshot => {
      setCardsBouwmarkt(snapshot.docs.map(doc => ({
        id: doc.id,
        cardBouwmarkt: doc.data()
      })));
    })
  }, []);

  const [cardDrogisterij, setCardsDrogisterij] = useState([]);

  useEffect(() => {
    db.collection('Drogisterij').onSnapshot(snapshot => {
      setCardsDrogisterij(snapshot.docs.map(doc => ({
        id: doc.id,
        cardDrogisterij: doc.data()
      })));
    })
  }, []);

  const [cardWoonwinkel, setCardsWoonwinkel] = useState([]);

  useEffect(() => {
    db.collection('Woonwinkel').onSnapshot(snapshot => {
      setCardsWoonwinkel(snapshot.docs.map(doc => ({
        id: doc.id,
        cardWoonwinkel: doc.data()
      })));
    })
  }, []);

  const [cardParfumerie, setCardsParfumerie] = useState([]);

  useEffect(() => {
    db.collection('Parfumerie').onSnapshot(snapshot => {
      setCardsParfumerie(snapshot.docs.map(doc => ({
        id: doc.id,
        cardParfumerie: doc.data()
      })));
    })
  }, []);

  function waarom() {
    alert('Deze webapp is gemaakt omdat ik vaak het probleem zag en had dat ik bij een winkel was en ik mijn ' +
          'portomonnee of mijn klantenkaart vergeten was.')
  }

  function klantenkaartVergeten() {
    alert('Let op! U wordt op dit moment doorverwezen naar Whatsapp, vermeld in dit bericht dat het gaat over ' +
          'een klantenkaart toevoegen aan de webapp. Soms wordt hier ook een bonnetje als bewijs bij gevraagd.')
  }

  function inkomsten() {
    alert('Zoals u waarschijnlijk weet kan je aan de hand van klantenkaarten korting op producten sparen, of zelfs ' +
    'gratis producten ontvangen. Deze wil ik dan ook nog geheim houden en dat als je geluk hebt deze korting ontvang. ' + 
    'Als de site helemaal klaar is wil ik deze inkomsten geven aan mensen die het minder hebben in de nederlandse samenleving!')
  }

  function paypal() {
    alert('Zoals u waarschijnlijk wel weet is een site hosten en onderhouden niet gratis. U kunt mij hiermee financieel ondersteunen ' +
    'door een kleine donatie te doen zodat deze site onderhouden kan blijven en dus ook de nederlanders die het minder hebben. Alle ' +
    'donaties zijn welkom! Let op u wordt nu naar whatsapp gebracht en daar kunt u het bedrag opgeven van uw donatie, ik zal u dan ' +
    'een tikkie sturen en persoonlijk bedanken!' )
  }

  function refresh(e) {

    window.location.reload(false); 

  }

  return (

    <div className="Dashboard" id='root'>

      <meta property='og:title' contenct='Klantenkaart-Vergeten.nl' />
      <meta property='og:image' content={logo} />
      <meta property='og:description' content='Nooit meer je gegevens moeten verkopen aan bedrijven om je eige korte te betalen. Verder heb je voortaan ook een groot aantal klantenkaarten bij de hand als je jouw eigen vergeten bent.' />
      <meta property='og:url' content='https://klantenkaart-vergeten.nl' />
      
      <div className="dashboard__menubar" id="sticky">
        <a href='https://wa.me/+31642257763?text=Ik%20zou%20graag%20een%20klantenkaart%20aan%20de%20webapp%20toe%20willen%20voegen.' onClick={klantenkaartVergeten.bind(this)}>
          <img className="dashboard__menubar__logo" src={logo} alt="logo"></img>
        </a>
          <IconButton onClick={refresh.bind(this)} style={{width: '100vw', color: 'white'}}>
            <RefreshRoundedIcon />
          </IconButton>
      </div>

      <div className="dashboard__contents">
        <div className="dashboard__supermarkten">
          <div className="dashboard__supermarkten__div" id="sticky">
            <h1 className="dashboard__supermarkten__text" id="sticky">Supermarkt</h1>
          </div>

          {
            card.map(({id, card}) => (
              <Klantenkaart id={id} item={['Supermarkten', id]} storeName={id} sortCode={card.SortCode} format={card.format} value={card.Value[Math.floor(Math.random()*card.Value.length)]} bg={card.bg} fg={card.fg} />
            ))
          }

        </div>
        <div className="dashboard__bouwmarkten">
          <div className="dashboard__bouwmarkten__div" id="sticky">
            <h1 className="dashboard__bouwmarkten__text">Drogisterij</h1>
          </div>

          {   
            cardDrogisterij.map(({id, cardDrogisterij}) => (
              <Klantenkaart key={'Bouwmarkt'+id.toUpperCase()} storeName={id.toUpperCase()} sortCode={cardDrogisterij.SortCode} format={cardDrogisterij.format} value={cardDrogisterij.Value[Math.floor(Math.random()*cardDrogisterij.Value.length)]} bg={cardDrogisterij.bg} fg={cardDrogisterij.fg} />
            ))
          }

        </div>

        <div className="dashboard__drogisterijen">
          <div className="dashboard__drogisterijen__div" id="sticky">
            <h1 className="dashboard__drogisterijen__text">Bouwmarkt</h1>
          </div>
                    
          {   
            cardBouwmarkt.map(({id, cardBouwmarkt}) => (
              <Klantenkaart key={'Drogisterij'+id.toUpperCase()} storeName={id.toUpperCase()} sortCode={cardBouwmarkt.SortCode} format={cardBouwmarkt.format} value={cardBouwmarkt.Value[Math.floor(Math.random()*cardBouwmarkt.Value.length)]} bg={cardBouwmarkt.bg} fg={cardBouwmarkt.fg} />
            ))
          }

        </div>
        
        <div className="dashboard__woonwinkel">
          <div className="dashboard__woonwinkel__div" id="sticky">
            <h1 className="dashboard__woonwinkel__text">Woonwinkel</h1>
          </div>

            {   
              cardWoonwinkel.map(({id, cardWoonwinkel}) => (
                <Klantenkaart key={'Woonwinkel'+id.toUpperCase()} storeName={id.toUpperCase()} sortCode={cardWoonwinkel.SortCode} format={cardWoonwinkel.format} value={cardWoonwinkel.Value[Math.floor(Math.random()*cardWoonwinkel.Value.length)]} bg={cardWoonwinkel.bg} fg={cardWoonwinkel.fg} />
              ))
            }

        </div>
        
        <div className="dashboard__parfumerie">
          <div className="dashboard__parfumerie__div" id="sticky">  
            <h1 className="dashboard__parfumerie__text">Parfumerie</h1>
          </div>

          {   
            cardParfumerie.map(({id, cardParfumerie}) => (
              <Klantenkaart key={'Parfumerie'} storeName={id.toUpperCase()} sortCode={cardParfumerie.SortCode} format={cardParfumerie.format} value={cardParfumerie.Value[Math.floor(Math.random()*cardParfumerie.Value.length)]} bg={cardParfumerie.bg} fg={cardParfumerie.fg} />
            ))
          }

        </div>
      </div>

      <div className="dashboard__buttom">

        <ul className='dashboard__buttom__ulist'>

          <a href='https://nl.linkedin.com/in/massimo-weijtens-62a63b174'>

            <li>OVER</li>

          </a>

          <a href='https://wa.me/+31642257763?text=Ik%20zou%20graag%20met%20u%20in%20contact%20komen%20over%20Klantenkaart-vergeten.nl'>

            <li>CONTACT</li>

          </a>
          
          <a href='#0'>
            <li onClick={waarom.bind(this)} >WAAROM</li>
          </a>
          
          <li onClick={inkomsten.bind(this)}>INKOMSTEN</li>

          <a href='https://wa.me/+31642257763?text=Ik%20zou%20graag%20met%20u%20in%20contact%20komen%20over%20Klantenkaart-vergeten.nl'>
            <li onClick={paypal.bind(this)}>STEUNEN</li>
          </a>
        </ul>

      </div>

    </div>
    
  );

}

export default App;
 